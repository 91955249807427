const INITIAL_STATE = {
    navbarminheight: '64px',
    sidebarminwidth: 300
};

const navbarReducer = (state = INITIAL_STATE) =>
{
    return state;
};

export default navbarReducer;