import React from 'react'

import { Box, Typography } from '@mui/material';

import Circle from './Circle';

function Spinner (props)
{
    const { zIndex } = props;

    return (
        <Box 
            component="div"
            sx={ {
                position: "fixed",
                height: "100vh",
                width: "100vw",
                top: 0,
                left: 0,
                backgroundColor: "#DDD",
                zIndex: zIndex ? zIndex : 20,
                opacity: 0.8,
            } }
        >
            <Box
                component="div"
                sx={ {
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                } }
            >
                <Circle
                    ariaLabel="spinner-loading"
                    wrapperStyle={ { display: "inherit", textAlign: "center" } }
                    sx={ { display: "inherit", textAlign: "center" } }
                    { ...props }
                />
                
                <Typography variant="body1" align="center">
                    Carregando...
                </Typography>
            </Box>
        </Box>
    )
};

export default Spinner;