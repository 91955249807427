import EspecialistasActionTypes from './action_types';

const initialState = {
    id: null,
    nome: '',
    sexo: '',
    dt_nasc: null,
    cpf: '',
    tipo_conselho: '',
    regiao_conselho: '',
    num_conselho: '',
    foto: ''
};

const especialistasReducer = (state = initialState, action) =>
{
    switch (action.type)
    {
        case EspecialistasActionTypes.SETESPECIALISTA:
            return {
                ...state,
                ...action.payload
            };
        case EspecialistasActionTypes.LOGOUTESPECIALISTA:
            return {
                initialState
            };
        default:
            return state;
    }
};

export default especialistasReducer;