import UsuarioActionTypes from './action_types';

const initialState = {
    id: null,
    email: '',
    senha: '',
    regra: null,
    status: '',
    codConfirmacao: '',
    ultAcesso: null,
};

const usuariosReducer = (state = initialState, action) =>
{
    switch (action.type)
    {
        case UsuarioActionTypes.SETUSUARIO:
            return {
                ...state,
                ...action.payload
            };
        case UsuarioActionTypes.LOGOUTUSUARIO:
            return {
                initialState
            };
        default:
            return state;
    }
};

export default usuariosReducer;