import EnderecosActionTypes from './action_types';

const initialState = {
    id: null,
    logradouro: '',
    numero: '',
    complemento: '',
    cep: '',
    bairro: '',
    cidade: '',
    estado: ''
};

const enderecosReducer = (state = initialState, action) =>
{
    switch (action.type)
    {
        case EnderecosActionTypes.SETENDERECO:
            return {
                ...state,
                ...action.payload
            };
        case EnderecosActionTypes.LOGOUTENDERECO:
            return {
                initialState
            };
        default:
            return state;
    }
};

export default enderecosReducer;