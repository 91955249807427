import { combineReducers } from 'redux';

import especialistasReducer from './especialistas/reducer';
import enderecosReducer from './enderecos/reducer';
import navbarReducer from './navbar/reducer';
import telefonesReducer from './telefones/reducer';
import usuariosReducer from './usuarios/reducer';

const rootReducer = combineReducers({
    especialistas: especialistasReducer,
    enderecos: enderecosReducer,
    navbar: navbarReducer,
    telefones: telefonesReducer,
    usuarios: usuariosReducer
});

export default rootReducer;