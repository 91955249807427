import TelefonesActionTypes from './action_types';

const initialState = [{
    id: null,
    tipo: '',
    ddd: '',
    numero: '',
}];

const telefonesReducer = (state = initialState, action) =>
{
    switch (action.type)
    {
        case TelefonesActionTypes.SETALLTELEFONES:
            return [
                ...action.payload
            ];
        case TelefonesActionTypes.LOGOUTTELEFONES:
            return [
                ...initialState
            ];
        default:
            return state;
    }
};

export default telefonesReducer;