import React, { createRef } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { SnackbarProvider } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';

import { store, persistor } from './redux/store';

import
{
    Button
} from '@mui/material';

import App from './App';

function Application ()
{
    const notistackRef = createRef();

    const onClickDismiss = (key) => () =>
    {
        notistackRef.current.closeSnackbar(key);
    }; 

    function handleSnackbarAction (key)
    {
        return (
            <Button onClick={ onClickDismiss(key) }>
                <CloseIcon sx={ { color: 'white' } } />
            </Button>
        );
    };

    return (
        <Provider store={ store }>
            <PersistGate loading={ null } persistor={ persistor }>
                <BrowserRouter>
                    <SnackbarProvider
                        maxSnack={ 4 }
                        preventDuplicate

                        ref={ notistackRef }

                        action={ handleSnackbarAction }

                        autoHideDuration={ 1000 }
                    >
                        <App/>
                    </SnackbarProvider>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
};

ReactDOM.render(
    <Application />,
    document.getElementById('root')
);