import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Box, CssBaseline, Container } from '@mui/material';

import { createTheme } from '@material-ui/core/styles';

import Spinner from './components/Spinner';

const PrivateRouteAdmin = lazy(() => import('./components/PrivateRoutes/PrivateRouteAdmin'));
const PrivateRouteEspecialista = lazy(() => import('./components/PrivateRoutes/PrivateRouteEspecialista'));
const PrivateRouteSignup = lazy(() => import('./components/PrivateRoutes/PrivateRouteSignup'));
const Navbar = lazy(() => import('./components/Navbar/Usuario'));
const Footer = lazy(() => import('./components/Footer'));

const Login = lazy(() => import('./views/Login'));
const SignUp = lazy(() => import('./views/SignUp'));
const RevalidarConta = lazy(() => import('./views/SignUp/RevalidarConta'));
const EsqueciSenha = lazy(() => import('./views/SignUp/EsqueciSenha'));
const ConfirmConta = lazy(() => import('./views/SignUp/ConfirmConta'));
const CadastroEspecialista = lazy(() => import('./views/CadastroEspecialista'));

const Anamnese = lazy(() => import ('./views/Anamnese'));

const ListaPacientes = lazy(() => import('./views/Usuario/ListaPacientes'));
const FichaPaciente = lazy(() => import('./views/Usuario/FichaPaciente'));
const ListaExames = lazy(() => import('./views/Usuario/ListaExames'));
const MinhaConta = lazy(() => import('./views/Usuario/MinhaConta'));

const DashboardAdmin = lazy(() => import('./views/Admin'));

function Error404 ()
{
    return (
        <h1>ERROR 404 - NOT FOUND</h1>
    );
};

function App ()
{
    return (
        <Routes>
            <Route
                path={ '/confirmacao/:codConfirm' }
                element={
                    <Suspense fallback={ <Spinner /> }>
                        <ConfirmConta />
                    </Suspense>
                }
            />

            <Route
                path={ '/anamnese/' }
                element={
                    <Suspense fallback={ <Spinner /> }>
                        <Anamnese />
                    </Suspense>
                }
            />

            <Route
                path={ '/' }
                element={
                    <Suspense fallback={ <Spinner /> }>
                        <Login />
                    </Suspense>
                }
            />
            <Route
                path={ '/login' }
                element={
                    <Suspense fallback={ <Spinner /> }>
                        <Login />
                    </Suspense>
                }
            />

            <Route
                path={ '/signup' }
                element={
                    <Suspense fallback={ <Spinner /> }>
                        <SignUp />
                    </Suspense>
                }
            />

            <Route
                path={ '/signup/cadastroEspecialista' }
                element={
                    <Suspense fallback={ <Spinner /> }>
                        <PrivateRouteSignup
                            user={ ['Ativo', 'Inativo'] }
                            component={ CadastroEspecialista } />
                    </Suspense>
                }
            />

            <Route
                path={ '/revalidation' }
                element={
                    <Suspense fallback={ <Spinner /> }>
                        <RevalidarConta />
                    </Suspense>
                }
            />

            <Route
                path={ '/esqueciSenha' }
                element={
                    <Suspense fallback={ <Spinner />}>
                        <EsqueciSenha />
                    </Suspense>
                }
            />

            <Route
                path={ '/aplicativo/*' }
                element={
                    <Suspense fallback={ <Spinner /> }>
                        <PrivateRouteEspecialista
                            user={ ['Ativo'] }
                            component={ Aplicativo }
                            enableFind={ false }
                        />
                    </Suspense>
                }
            />

            <Route
                path={ '/usuario/*' }
                element={
                    <Suspense fallback={ <Spinner /> }>
                        <PrivateRouteEspecialista
                            user={ ['Ativo', 'Inativo'] }
                            component={ Usuario }
                            enableFind={ false }
                        />
                    </Suspense>
                }
            />

            <Route
                path={ '/admin/*' }
                element={
                    <Suspense fallback={ <Spinner /> }>
                        <PrivateRouteAdmin
                            component={ Administrador }
                            enableFind={ false }
                        />
                    </Suspense>
                }
            />

            <Route
                path={ '*' }
                element={
                    <Suspense fallback={ <Spinner /> }>
                        <PrivateRouteEspecialista
                            user={ ['Ativo', 'Inativo'] }
                            component={ Error404 }
                            enableFind={ false }
                        />
                    </Suspense>
                }
            />
        </Routes>
    );
};

function Aplicativo (props)
{
    const { token, especialistasId } = props;

    const darkTheme = createTheme({
        palette: {
            mode: 'dark'
        },
    });

    return (
        <Box
            sx={ {
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            } }
        >
            <CssBaseline />

            <Suspense fallback={ <Spinner /> }>
                <Navbar theme={ darkTheme } token={ token } especialistasId={ especialistasId } />
            </Suspense>

            <Container component='main' maxWidth={ false } sx={ { mt: 3 } }>
                <Routes>
                    <Route
                        path={ '/' }
                        element={
                            <Suspense fallback={ <Spinner /> }>
                                <PrivateRouteEspecialista
                                    user={ ['Ativo'] }
                                    component={ ListaPacientes }
                                    enableFind={ true }
                                />
                            </Suspense>
                        }
                    />
                    <Route
                        path={ '/pacientes' }
                        element={
                            <Suspense fallback={ <Spinner /> }>
                                <PrivateRouteEspecialista
                                    user={ ['Ativo'] }
                                    component={ ListaPacientes }
                                    enableFind={ true }
                                />
                            </Suspense>
                        }
                    />

                    <Route
                        path={ '/paciente' }
                        element={
                            <Suspense fallback={ <Spinner /> }>
                                <PrivateRouteEspecialista
                                    user={ ['Ativo'] }
                                    component={ FichaPaciente }
                                    enableFind={ true }
                                />
                            </Suspense>
                        }
                    />

                    <Route
                        path={ '/exame' }
                        element={
                            <Suspense fallback={ <Spinner /> }>
                                <PrivateRouteEspecialista
                                    user={ ['Ativo'] }
                                    component={ ListaExames }
                                    enableFind={ true }
                                />
                            </Suspense>
                        }
                    />

                    <Route path={ '*' } element={ Error404 } />
                </Routes>
            </Container>
            <Suspense fallback={ <Spinner /> }>
                <Footer />
            </Suspense>
        </Box>
    );
};

function Usuario (props)
{
    const { especialistasId, token, usuariosId } = props;

    return (
        <Box
            sx={ {
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            } }
        >
            <CssBaseline />

            <Suspense fallback={ <Spinner /> }>
                <MinhaConta especialistasId={ especialistasId } token={ token } usuariosId={ usuariosId } />
            </Suspense>
        </Box>
    );
};

function Administrador (props)
{
    const { token, usuariosId } = props;

    return (
        <Box
            sx={ {
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            } }
        >
            <CssBaseline />

            <Suspense fallback={ <Spinner /> }>
                <DashboardAdmin token={ token } usuariosId={ usuariosId } />
            </Suspense>
        </Box>
    );
};

export default App;