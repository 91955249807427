import React from 'react';

const RADIUS = 20;

const getPath = (radius) =>
{
    return ['M' + radius + ' 0c0-9.94-8.06', radius, radius, radius].join('-');
};

const getViewBoxSize = (maxStrokeWidth, radius) =>
{
    const startingPoint = -radius - (maxStrokeWidth / 2) + 1;

    const endpoint = (radius * 2) + maxStrokeWidth;

    return [startingPoint, startingPoint, endpoint, endpoint].join(' ');
};

function Circle (props)
{
    const {
        size = 50,
        color = '#121212',
        secondaryColor = 'gray',
        ariaLabel = 'spinner-loading',
        wrapperStyle = {},
        wrapperClass = {},
        strokeWidth = 5,
        strokeWidthSecondary = 5
    } = props;

    const maxStrokeWidth = Math.max(strokeWidth, strokeWidthSecondary);

    return (
        <div
            style={ { ...wrapperStyle, padding: 3 } }
            className={ wrapperClass }
            data-testid="oval-loading"
        >
            <svg
                width={ size }
                height={ size }
                viewBox={ getViewBoxSize(
                    maxStrokeWidth,
                    RADIUS,
                ) }
                xmlns="http://www.w3.org/2000/svg"
                stroke={ color }
                data-testid="oval-svg"
                aria-label={ ariaLabel }
            >
                <g fill="none" fillRule="evenodd">
                    <g
                        transform="translate(1 1)"
                        strokeWidth={ maxStrokeWidth }
                    >
                        <circle
                            strokeOpacity=".5"
                            cx="0"
                            cy="0"
                            r={ RADIUS }
                            stroke={ secondaryColor }
                            strokeWidth={ maxStrokeWidth }
                        />
                        <path d={ getPath(RADIUS) }>
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                from="0 0 0"
                                to="360 0 0"
                                dur="1s"
                                repeatCount="indefinite"
                            />
                        </path>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default Circle;